/* global _czc */


// CSS

import '../css/app.pcss';

import '../vendors/trix.css';

// Helpers 

const readStringifiedJson = str => {
  try {
    return JSON.parse ( str );
  }
  catch ( error ) {
    return;
  }
};

// Translations

import translations from '../../translation/chi.json';

// Elm

import { Elm } from '../../src/Main.elm';

var app = Elm.Main.init ({
  flags: {
    token: localStorage.token || null,
    translations: translations,
    hostname: location.hostname
  }
});


document.addEventListener ( 'dragover', event => {
  // prevent default to allow drop
  event.preventDefault ();
}, false );

document.addEventListener ( 'drop', event => {
  // prevent default action (open as link for some elements)
  event.preventDefault ();
}, false );

document.addEventListener ( 'keydown', event => {
  if ( event.key == 's' && event.ctrlKey ) {
    event.preventDefault ();
  }
  else if ( event.key == 'e' && event.ctrlKey ) {
    event.preventDefault ();
  }
  else if ( event.key == 'z' && event.ctrlKey ) {
    event.preventDefault ();
  }
}, false );


document.addEventListener('keydown', event => {
  var keyCode = {
    keyLeft: 37,
    keyRight: 39
  };
  if (event.keyCode == keyCode.keyLeft) {
    setImgIndex('previous');
  }
  else if (event.keyCode == keyCode.keyRight) {
    setImgIndex('next');
  }
})


app.ports.seekTime.subscribe ( milliseconds => {
  const video = document.querySelector ( 'video#source-file' );
  video.currentTime = milliseconds / 1000;
  video.play ();
});


const connectSocket = () => {
  var socket = new WebSocket ( 'ws://localhost:16699' );

  socket.addEventListener ( 'close', event => {
    app.ports.websocketClosed.send ( event.code );
  });

  socket.addEventListener ( 'message', event => {
    let message = JSON.parse ( event.data );
    handleSocketEvent ( message['Command'], message );
  });
};

connectSocket ();


app.ports.connectSocket.subscribe (() => {
  connectSocket ();
});

const handleSocketEvent = ( event, data ) => {
  switch ( event ) {
    case 'ProcessProgressEvent':
      handleProcessProgressEvent ( data );
      break;

    case 'ProcessFailureEvent':
      handleProcessFailureEvent ( data );
      break;

    default:
      break;
  }
};


const handleProcessProgressEvent = data => {
  let uuid = data.Parameter.Id;

  switch ( data.Parameter.Status ) {
    case 1:
      // When the video processing job is ready to start:
      app.ports.processingStatusUpdate.send ({
        Id: uuid,
        Status: 1 // 'ready' 
      });
      break;

    case 2: {
      // When the video processing job is ongoing:
      let progress = data.Parameter.Progress;

      app.ports.processingStatusUpdate.send ({
        Id: uuid,
        Status: 2, // 'ongoing'
        Progress: progress
      });

      break;
    }

    case 3: {
      // When the video processing job is completed:
      app.ports.processingStatusUpdate.send ({
        Id: uuid,
        Status: 3 // 'completed'
      });

      break;
    }

    default:
      // When God knows why an unknown status code is received:
      break;
  }
};


const handleProcessFailureEvent = data => {
  app.ports.processingStatusUpdate.send ({
    uuid: data.Id,
    status: 'failed',
    reason: data.ErrorMsg
  });
};


app.ports.undoTrix.subscribe (() => {
  document.activeElement.editor.undo ();
});


app.ports.redoTrix.subscribe (() => {
  document.activeElement.editor.redo ();
});


app.ports.scrollIntoView.subscribe ( nodeID => {
  document.getElementById ( nodeID ).scrollIntoView ( true );
});


const promptUserBeforeUnload = event => {
  event.preventDefault ();
  event.returnValue = '';
  /*
      Case: Uesr click go-back button -> Prompt(leave or cancel ?) -> Cancel
    ----------------------------------------------------------------------------------------------
      Probs: In this case, although the "promptUserBeforeUnload" prevent the redirection, the url was changed.
    -----------------------------------------------------------------------------------------------------------
      Solution: To avoid the troubles brought by the changed url, we have to: (1) get the url of current page (2) push the url to history
  */
  history.pushState(null, "", window.url); // (2) push the url to history
};

app.ports.addBeforeUnloadPrompt.subscribe (() => {
  window.addEventListener ( 'beforeunload', promptUserBeforeUnload );
  window.url = location.pathname; // (1) get the url of currentpage
});


app.ports.removeBeforeUnloadPrompt.subscribe (() => {
  window.removeEventListener ( 'beforeunload', promptUserBeforeUnload );
});


app.ports.copyAll.subscribe(msg => {
  var copyAllTarget = document.getElementById('copyAllTarget');
  window.getSelection().selectAllChildren(copyAllTarget);
});


function setImgIndex(action) {
  var imgToPreview = document.getElementsByClassName('imgToPreview');
  var len = imgToPreview.length;
  const imgArr = new Array(len);
  var modalImg = document.getElementById('modalImg');
  var index = modalImg.getAttribute('index');
  for (var i = 0; i < len; i++) {
    imgArr[i] = imgToPreview[i].id;
  }
  var pos = imgArr.indexOf(index);
  switch (action) {
    case 'next':
      var newIndex = imgArr[(pos + 1) % len];
      break;
    case 'previous':
      var newIndex = imgArr[(pos - 1 + len) % len];
      break;
    default:
      var newIndex = index;
      break;
  }
  modalImg.setAttribute('index', newIndex);
  modalImg.src = document.getElementById(newIndex).src;
}


app.ports.prevImg.subscribe(() => {
  setImgIndex('previous');
});


app.ports.nextImg.subscribe(() => {
  setImgIndex('next');
});
// Web components

class RichTextPreview extends HTMLElement {
  constructor () {
    super ();
  }

  connectedCallback () {
    this.setContent ();
  }

  attributeChangedCallback () { 
    this.setContent (); 
  }

  static get observedAttributes () {
    return [ 'content-value' ];
  }

  setContent () {
    const content = this.getAttribute ( 'content-value' );
    this.innerHTML = content;
  }
}

customElements.define ( 'rich-text-preview', RichTextPreview );


// Analytics 

let previousHref = document.location.href;

window.onload = () => {
  let body = document.querySelector ( 'body' );

  let observer = new MutationObserver ( mutations => {
    mutations.forEach ( mutation => {
      if ( document.location.href != previousHref ) {
        previousHref = document.location.href;
        _czc.push ([ '_trackPageview', location.pathname ]);
      }
    });
  });

  const config = {
    childList: true,
    subtree: true
  };

  observer.observe ( body, config );
};



export default {
  app: app
};